<template>
  <div class="app" :class="`${openedleft ? 'opened-left-panel' : ''}${openedcontests ? ' opened-contests-panel' : ''
    }`
    ">

    <Head :flagShow="openedleft" :isMobile="isMobileFlag" @menuClick="menuClick" />
    <div class="wrapper">
      <div class="wrapper__inner">
        <div class="container">
          <div class="rank_about__wrapper lp">

            <div class="lp1">
              <div class="lp3__signs">
                <img src="../../assets/images/signs.png" alt />
              </div>
              <div class="lp-heading lp3__heading">
                <h3 class="lp-title lp3__title">
                  {{ $t("您可以达到") }}
                  <span v-if="userVipData.length > 0">{{
                    userVipData[userVipData.length - 1].level_name
                  }}</span>
                </h3>
                <p class="lp-toptext">{{ $t("享受最佳游戏体验和优质服务") }}</p>
              </div>
              <div class="rank-slider">
                <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
                  <swiper-slide v-for="(item, index) in userVipData" :key="`userVipData${index}`">
                    <div class="rank-slider__slide">
                      <div class="rank-slider__slider-body">
                        <div class="rank-slider__image">
                          <img :src="item.image || item.levelImg" />
                        </div>
                        <h4 class="rank-slider__title">
                          {{ item.level_name }}
                        </h4>
                        <div class="rank-slider__details" v-if="item.vip_condition.length">
                          <div class="rank-slider__detail" v-for="(im, it) in item.vip_condition"
                            :key="`vip_condition${it}`">
                            <p class="rank-slider__label">{{ $t(im.key) }}</p>：
                            <p class="rank-slider__cashback">{{ im.value }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
              <div class="lijin-btns-box" v-if="currentVip">

                <div class="lijin-btn">
                  <div class="lijin-icon-box">
                    <img src="@/assets/images/lijin-icon1.png" alt="">
                  </div>
                  <div class="lijin-btn-content">
                    <div class="lijin-btn-title">{{ $t("周礼金") }}:</div>
                    <div class="lijin-btn-price">{{ currentVip.week_reward_amount }}</div>
                  </div>
                  <el-button class="btn-yellow" :disabled="currentVip.week_reward_amount == 0" @click="receiveLevelReward(currentVip, 1)" :loading="currentVip.loading1">{{ $t("领取") }}</el-button>
                </div>

                <div class="lijin-btn">
                  <div class="lijin-icon-box">
                    <img src="@/assets/images/lijin-icon2.png" alt="">
                  </div>
                  <div class="lijin-btn-content">
                    <div class="lijin-btn-title">{{ $t("月礼金") }}:</div>
                    <div class="lijin-btn-price">{{ currentVip.month_reward_amount }}</div>
                  </div>
                  <el-button class="btn-yellow" :disabled="currentVip.month_reward_amount == 0" @click="receiveLevelReward(currentVip, 2)" :loading="currentVip.loading2">{{ $t("领取") }}</el-button>
                </div>

              </div>
            </div>
            <div class="lp3">
              <div class="lp1__box">
                <h2 class="lp1__title">
                  {{ $t("A LOT OF BENEFITS WITH RANK SYSTEM") }}
                </h2>
                <div class="lp1__img">
                  <img src="../../assets/images/racoon-top.png" class="racoon-top"/>
                  <img src="../../assets/images/racoon-top-bg.png" class="racoon-top-bg"/>
                </div>
                <ul class="lp1__list">
                  <li class="lp1__list-item">
                    <div class="lp1__list-img lp1__list-img_1">01</div>
                    <div>
                      <p class="lp1__list-heading">
                        {{ $t("Levels and Rewards") }}
                      </p>
                      <p class="lp1__list-text">
                        {{ $t("The more you play - the more you gain") }}
                      </p>
                    </div>
                  </li>
                  <li class="lp1__list-item">
                    <div class="lp1__list-img lp1__list-img_2">02</div>
                    <div>
                      <p class="lp1__list-heading">
                        {{ $t("Increased Cashback") }}
                      </p>
                      <p class="lp1__list-text">
                        {{ $t("Receive up to 1.3% of your coins back") }}
                      </p>
                    </div>
                  </li>
                  <li class="lp1__list-item">
                    <div class="lp1__list-img lp1__list-img_3">03</div>
                    <div>
                      <p class="lp1__list-heading">
                        {{ $t("Exclusive Promotions") }}
                      </p>
                      <p class="lp1__list-text">
                        {{ $t("Reach higher rank to unlock unique benefits") }}
                      </p>
                    </div>
                  </li>
                  <li class="lp1__list-item">
                    <div class="lp1__list-img lp1__list-img_4">04</div>
                    <div>
                      <p class="lp1__list-heading">{{ $t("VIP CLUB") }}</p>
                      <p class="lp1__list-text">
                        {{
                          $t(
                            "Become a part of VIP Club to get exclusive bonuses"
                          )
                        }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="lp2">
              <div class="lp-heading">
                <h3 class="lp-title">{{ $t("如何工作") }}</h3>
                <p class="lp-toptext">{{ $t("开始您的游戏之路") }}</p>
              </div>
              <ul class="lp2__items">
                <li>
                  <div class="lp2__item">
                    <div class="lp2__item-img lp2__item-img_1">
                      <img src="../../assets/images/1.png" alt />
                    </div>
                    <div class="lp2__item-num">
                      <img src="../../assets/images/1.svg" alt />
                    </div>
                    <p class="lp2__item-heading">
                      {{ $t("Login on Cyber Raccoon") }}
                    </p>
                    <p class="lp2__item-text">
                      {{ $t("rank_system_desc1") }}
                    </p>
                  </div>
                </li>
                <li>
                  <div class="lp2__item">
                    <div class="lp2__item-img lp2__item-img_1">
                      <img src="../../assets/images/2.png" alt />
                    </div>
                    <div class="lp2__item-num">
                      <img src="../../assets/images/02.svg" alt />
                    </div>
                    <p class="lp2__item-heading">
                      {{ $t("Play favorite games") }}
                    </p>
                    <p class="lp2__item-text">
                      {{ $t("rank_system_desc2") }}
                    </p>
                  </div>
                </li>
                <li>
                  <div class="lp2__item">
                    <div class="lp2__item-img lp2__item-img_1">
                      <img src="../../assets/images/3.png" alt />
                    </div>
                    <div class="lp2__item-num">
                      <img src="../../assets/images/03.svg" alt />
                    </div>
                    <p class="lp2__item-heading">
                      {{ $t("Increase your rank") }}
                    </p>
                    <p class="lp2__item-text">
                      {{ $t("rank_system_desc3") }}
                    </p>
                  </div>
                </li>
              </ul>
              <router-link to="/all-game" class="button button_xl button_gre button_center btn-yellow">
                <span class="button__inner">
                  <span class="button__text">{{ $t("开始游戏") }}</span>
                </span>
              </router-link>
            </div>
            <div class="lp4">
              <div class="bg-img">
                <img src="@/assets/images/about-vip-bg.png" alt="">
              </div>
              <h2 class="title">{{ $t("关于贵宾会") }}</h2>
              <p class="sub-title">{{ $t("最佳游戏体验和高水平服务") }}</p>
              <div class="lp4-content-box">
                <h3 class="sub-title2">{{ $t("成为贵宾俱乐部的会员意味着您可以享受") }}</h3>
                <ul>
                  <li>
                    <img src="../../assets/images/checkbox.png" height="20" width="20" alt />
                    {{ $t("私人贵宾经理") }}
                  </li>
                  <li>
                    <img src="../../assets/images/checkbox.png" height="20" width="20" alt />
                    {{ $t("更高的返现奖励") }}
                  </li>
                  <li>
                    <img src="../../assets/images/checkbox.png" height="20" width="20" alt />
                    {{ $t("每周和每月奖金") }}
                  </li>
                  <li>
                    <img src="../../assets/images/checkbox.png" height="20" width="20" alt />
                    {{ $t("其他额外津贴") }}
                  </li>
                </ul>
                <h4 class="el-m-b-15">{{ $t("加入我们俱乐部的邀请") }}</h4>
                <a :href="$t('join_vip_club_url')" target="_blank" rel="noopener noreferrer nofollow"
                  class="button button_lg button_blue button_center">
                  <span class="button__inner">
                    <span class="button__text">{{ $t("加入") }}</span>
                  </span>
                </a>
              </div>
              
            </div>
            <h2 class="about-title">{{ $t("关于赛博浣熊") }}</h2>
            <div class="faq-items">
              <div class="faq-items__column">
                <div class="faq-element" @click="faqClick(item)" v-for="(item, index) in faqListleft"
                  :key="`faqListleft${index}`" :class="item.select ? 'faq-show' : ''">
                  <div class="faq-element__wrapper">
                    <h3 class="faq-element__title">{{ $t(item.title) }}</h3>
                    <div class="faq-element__icon-block">
                      <svg width="20" height="20" focusable="false" aria-hidden="true" class="faq-element__icon-arrow">
                        <use xlink:href="../../assets/fonts/svg-sprite.svg#icon-arrow-down" class="svg-use" />
                      </svg>
                    </div>
                  </div>
                  <p class="faq-element__description" v-html="$t('about_us_desc' + item.id)"></p>
                </div>
              </div>
              <div class="faq-items__column">
                <div class="faq-element" @click="faqClick(item)" v-for="(item, index) in faqListright"
                  :key="`faqListright${index}`" :class="item.select ? 'faq-show' : ''">
                  <div class="faq-element__wrapper">
                    <h3 class="faq-element__title">{{ $t(item.title) }}</h3>
                    <div class="faq-element__icon-block">
                      <svg width="20" height="20" focusable="false" aria-hidden="true" class="faq-element__icon-arrow">
                        <use xlink:href="../../assets/fonts/svg-sprite.svg#icon-arrow-down" class="svg-use" />
                      </svg>
                    </div>
                  </div>
                  <p class="faq-element__description" v-html="$t('about_us_desc' + item.id)"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Foot />
    </div>
    <asidebar :flagShow="openedleft" @menuClick="menuClick"></asidebar>
    <tabbar :flagShow="openedleft" @menuClick="menuClick" @contestsShow="contestsShow"></tabbar>
    <div class="app-overlay" v-if="isMobileFlag" :class="openedleft ? 'visible' : ''" @click="menuClick"></div>
  </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Foot from "@/components/Foot.vue";
import asidebar from "@/components/aside.vue";
import svgIcon from "@/components/svg.vue";
import tabbar from "@/components/tabbar.vue";
import { user_vip_api, user_vip_api2, user_receiveLevelReward } from "@/api/user";
export default {
  components: {
    Head,
    Foot,
    asidebar,
    svgIcon,
    tabbar
  },
  computed: {
    currentVip(){
      return this.userVipData.find(item=>item.id==this.$store.state.userInfo?.level_id)
    },
    faqListleft: function () {
      var list = this.faqList.filter(item => item.title != this.$t(item.title));
      var arrTemp = [];
      for (var i = 0; i < list.length; i++) {
        if (i % 2 == 0) {
          arrTemp.push(list[i]);
        }
      }
      return arrTemp;
    },
    faqListright: function () {
      var list = this.faqList.filter(item => item.title != this.$t(item.title));
      var arrTemp = [];
      for (var i = 0; i < list.length; i++) {
        if (i % 2 != 0) {
          arrTemp.push(list[i]);
        }
      }
      return arrTemp;
    },
  },
  data() {
    return {
      curTime: 0,
      timeId: null,
      userVipData: [],
      swiperOptionThumbs: {
        loop: false,
        autoplay: false,
        slidesPerView: 2,
        spaceBetween: 23,
        centeredSlides: true,
        breakpoints: {
          540: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
        },
      },
      faqCur: 0,
      faqData: [],
      faqList: [
        {
          id: 0,
          title: "什麼是 Cyber Raccoon VIP 俱樂部？",
          content: "",
          select: false,
        },
        {
          id: 1,
          title: "如何成為貴賓俱樂部的一員？",
          content: "",
          select: false,
        },
        {
          id: 2,
          title: "加入貴賓俱樂部有什麼好處？",
          content: "",
          select: false,
        },
        {
          id: 3,
          title: "什麼會影響每週/每月的獎金？",
          content: "",
          select: false,
        },
        {
          id: 4,
          title: "我想成為貴賓俱樂部的一員，但我還沒有達到13級，我該怎麼辦？",
          content: "",
          select: false,
        },
        {
          id: 5,
          title: "VIP Club 會員有什麼特別優惠嗎？",
          content: "",
          select: false,
        },
        {
          id: 6,
          title:
            "我是會員，我還沒有收到過任何獎金。為什麼？它們什麼時候傳送給使用者，多久傳送一次？",
          content: "",
          select: false,
        },
      ],
    };
  },
  methods: {
    time(time, curTime, type, item, key) {
      let nextTime;
      time = time * 1000
      if (type == "周") {
        nextTime = time + 7 * 24 * 60 * 60 * 1000
      } else {
        // const date = new Date(time)
        // const month = date.getMonth()+1
        // nextTime = date.setMonth(month)
        nextTime = time + 30 * 24 * 60 * 60 * 1000
      }

      const rTime = (nextTime - curTime) / 1000
      // 判断时间时候为负数，负数改变状态
      if (rTime <= 0) {
        item[key] = 0
        this.$forceUpdate()
        return
      }

      const h = parseInt(rTime / 3600).toString()
      const m = parseInt(rTime % 3600 / 60).toString()
      const s = parseInt(rTime % 3600 % 60).toString()
      if (h > 24) {
        return Math.ceil(h / 24) + this.$t("天")
      } else {
        return `${h.length == 1 ? "0" + h : h}:${m.length == 1 ? "0" + m : m}:${s.length == 1 ? "0" + s : s}`
      }
    },
    async receiveLevelReward(item, reward_type) {
      if (item[reward_type == 1 ? 'week_is_receive_vip_reward' : 'month_is_receive_vip_reward'] == 1) {
        this.$message.error(this.$t('请计时结束再领取下次奖励'));
        return
      }
      item['loading' + reward_type] = true
      this.$forceUpdate()
      const res = await user_receiveLevelReward({
        level_id: item.id,
        reward_type
      })
      if (res.data.code == 1) {
        await this.getUserVip(false)
        this.$message({
          message: this.$t('领取成功'),
          type: 'success'
        });
      } else {
        this.$message.error(res.data.msg);
      }
      item['loading' + reward_type] = false
      this.$forceUpdate()
    },
    async getUserVip(init) {
      await user_vip_api().then((res) => {
        if (res && res.data.code == 1) {
          let arr = res.data.data;
          arr.forEach(item => {
            item.week_is_receive_vip_reward = 1
            item.month_is_receive_vip_reward = 1
          });
          if (init) {
            const page = arr.findIndex(item => item.id === this.$store.state.userInfo.level_id)
            this.$refs?.swiperThumbs?.swiper.slideTo(page);
          }
          if (arr.length > 0) {
            for (let i in arr) {
              let num = arr[i].level_name.substring(
                3,
                arr[i].level_name.length
              );
              let keys = i * 1 + 1 > 20 ? 20 : i * 1 + 1;
              arr[i].levelImg = require(`../../assets/images/r${keys}.png`);
              arr[i].levelNum = Number(num);
            }
            this.userVipData = arr;
          }
        }
      });
    },
    async getUserVip2() {
      await user_vip_api2().then((res) => {
        if (res && res.data.code == 1) {
          let arr = res.data.data;
          if (arr.length > 0) {
            for (let i in arr) {
              let num = arr[i].level_name.substring(
                3,
                arr[i].level_name.length
              );
              let keys = i * 1 + 1 > 20 ? 20 : i * 1 + 1;
              arr[i].levelImg = require(`../../assets/images/r${keys}.png`);
              arr[i].levelNum = Number(num);
            }
            this.userVipData = arr;
          }
        }
      });
    },
    menuClick() {
      this.openedleft = !this.openedleft;
    },
    contestsClick() {
      this.openedcontests = !this.openedcontests;
    },
    contestsShow() {
      this.openedcontests = true;
    },
    faqClick(item) {
      if (!item.select) {
        for (let i = 0; i < this.faqList.length; i++) {
          this.faqList[i].select = false;
        }
        item.select = true;
      } else {
        item.select = false;
      }
    },
  },
  mounted() {
    this.curTime = new Date().getTime()
    this.timeId = setInterval(() => {
      this.curTime = new Date().getTime()
    }, 1000)
    if (this.$store.state.isLoginStatu) {
      this.getUserVip(true);
    } else {
      this.getUserVip2();
    }
  },
  watch: {
    '$store.state.isLoginStatu': function (v) {
      if (v) {
        this.getUserVip(true);
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timeId)
  },
  created() {
    this.isPhone();
  },
};
</script>
<style scoped lang="less">
.content {
  padding-left: 24px;
  padding-right: 24px;
}

.lp {
  position: relative;
  margin-top: -60px;
}

.lp .vip-club-h4 {
  margin-top: 30px;
  margin-bottom: 5px;
  color: #fff;
}

.lp_2 .lp1 {
  padding: 183px 0 0;
}

.lp_2 .lp2 {
  padding-top: 55px;
}

.lp1 {
  padding: 132px 0 59px;
}

.lp1,
.lp1__box {
  position: relative;
}

.lp1__title {
  position: relative;
  margin: 0 0 45px;
  max-width: 660px;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
  color: #fff;
  z-index: 2;
  @media (max-width:768px) {
    font-size: 25px !important;
    line-height: 1.3 !important;
  }
}

.lp1__title span {
  color: #fa305c;
}

.lp1__img {
  position: absolute;
  right: 0;
  top: 0;
  width: 387px;
  height: 438px;
  overflow: visible;
  user-select: none;
  pointer-events: none;
  .racoon-top-bg{
    max-width: 283%;
    width: 283%;
    position: absolute;
    left: -151%;
    top: -68%;
    z-index: -1;
  }
}

.lp1__img .racoon-top {
  width: 100%;
  height: 100%;
  object-fit: contain;
  flex-shrink: 0;
}

.lp1__img_2 {
  right: -124px;
  top: -183px;
  width: 751px;
}

.lp1__list {
  position: relative;
  max-width: 530px;
}


.lp1__list-item {
  display: flex;
  margin: 0 0 5px;
  font-weight: 600;
  margin-bottom: 20px;
  align-items: center;
}


.lp1__list-img {
  position: relative;
  font-size: 40px;
  font-weight: normal;
  color: #C2F6E6;
  margin-right: 20px;
  font-family: PingFangSC, PingFang SC;
  line-height: 1;
  height: 1.3em;
  @media (max-width:768px) {
    width: 5px;
    border-radius: 100vh;
    overflow: hidden;
    color: transparent !important;
    background-color: #FFD700;
    flex-shrink: 0;
    &::after{
      display: none !important;
    }
    &.lp1__list-img_2{
      background-color: #7E6ED6;
    }
    &.lp1__list-img_3{
      background-color: #55FF8E;
    }
    &.lp1__list-img_4{
      background-color: #FF9057;
    }
  }
  &::after{
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5px;
    border-radius: 100vh;
    background: #FFD700;
  }
  &.lp1__list-img_2::after{
    background: #7E6ED6;
  }
  &.lp1__list-img_3::after{
    background: #55FF8E;
  }
  &.lp1__list-img_4::after{
    background: #FF9057;
  }
  
}

.lp1__list-heading {
  margin: 0 0 3px;
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #C2F6E6;
  font-weight: 400;
}

.lp1__list-text {
  margin: 0;
  font-size: 15px;
  line-height: 23px;
  font-weight: 400;
  color: #86AFA2;
}

.lp2 {
  position: relative;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lp2 .button {
  margin: 30px auto 0;
  display: inline-block;
}

.lp-heading {
  margin: 0 auto 41px;
}

.lp-title {
  margin: 0 auto 21px;
  font-size: 40px;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  @media (max-width:768px) {
    font-size: 25px !important;
    margin-bottom: 0 !important;
  }
}

.lp-title span {
  color: #65DA19;
}

.lp-toptext {
  max-width: 810px;
  font-size: 18px;
  line-height: 1.3;
  color: #AED9CC;
  @media (max-width:768px) {
    font-size: 12px;
    max-width: 240px;
  }
}

.lp-toptext,
.lp-toptextsm {
  margin: 0 auto;
  text-align: center;
}

.lp2__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}

.lp2__items>* {
  position: relative;
  padding: 0 6px;
  width: 33.33%;
}

.lp2__items> :last-child:after {
  display: none;
}

.lp2__item {
  position: relative;
  padding: 149px 20px 32px 38px;
  border-radius: 20px;
  background: linear-gradient(to bottom, #0DAC57, #075240);
  font-weight: 600;
  height: 100%;
}

.lp2__item-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.lp2__item-img img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.lp2__item-img_1 {
  left: 20px;
  top: 20px;
}

.lp2__item-img_1 img {
  width: 110px;
  height: 110px;
  object-fit: contain;
}

.lp2__item-img_2 {
  left: -67px;
  top: -93px;
}

.lp2__item-img_2 img {
  width: 340px;
}

.lp2__item-img_3 {
  left: -87px;
  top: -95px;
}

.lp2__item-img_3 img {
  width: 336px;
}

.lp2__item-img_4 {
  left: -75px;
  top: -99px;
}

.lp2__item-img_4 img {
  width: 340px;
}

.lp2__item-img_5 {
  left: 7px;
  top: 5px;
}

.lp2__item-img_5 img {
  width: 146px;
}

.lp2__item-img_6 {
  left: 5px;
  top: 10px;
}

.lp2__item-img_6 img {
  width: 139px;
}

.lp2__item-num {
  position: absolute;
  right: 23px;
  top: 32px;
  width: 153px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.lp2__item-heading {
  margin: 0 0 9px;
  font-size: 20px;
  line-height: 1.32;
  text-transform: uppercase;
  color: #C2F6E6;
  -webkit-line-clamp: 2;
}

.lp2__item-heading,
.lp2__item-text {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.lp2__item-text {
  min-height: 70px;
  margin: 0;
  font-size: 15px;
  line-height: 23px;
  color: #58D3AD;
  -webkit-line-clamp: 3;
}

.btn {
  height: 52px;
  margin: 30px auto 0;
  display: block;
  text-transform: uppercase;
}

.lp3 {
  position: relative;
  padding: 10px 0;
}

.lp3__signs {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.lp3__heading {
  margin-bottom: 43px;
}

.lp3__title {
  margin-bottom: 8px;
}

.lp4 {
  position: relative;
  width: 100%;
  min-height: 300px;
  border-radius: 20px;
  margin-bottom: 50px;
  padding: 75px;
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 500px;
  .title{
    color: #FFF;
    font-weight: normal;
    @media (max-width:768px) {
      font-size: 25px;
      text-align: center;
      line-height: 1;
      margin-bottom: 9px;
    }
  }
  .bg-img{
    position: absolute;
    width: 400px;
    height: 400px;
    left: 0;
    @media (max-width:768px) {
      width: 120px;
      height: 120px;
      top: 50%;
      left: -30px;
    }
    img{
      max-width: 250%;
      width: 250%;
      position: absolute;
      left: -106%;
      top: -68%;
    }
  }
}

.lp4 h2,
.lp4 h3,
.lp4 h4,
.lp4 p {
  margin: 0;
  padding: 0;
}

.lp4 h2 {
  font-size: 40px;
  line-height: 60px;
  text-transform: uppercase;
  margin: 0 0 20px;
}

.lp4 p {
  max-width: 355px;
  font-size: 18px;
  line-height: 150%;
  color: #AED9CC;
}

.lp4 h3,
.lp4 p {
  font-weight: 400;
  margin-bottom: 25px;
}

.lp4 h3 {
  max-width: 441px;
  font-style: normal;
  font-size: 21px;
}

.lp4 ul {
  margin-bottom: 25px;
}

.lp4 li {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.lp4 li img {
  margin-right: 14px;
}

.lp4 h4 {
  font-weight: 400;
  font-size: 17px;
  color: #D7FFC4;
  max-width: 400px;
}

.lp4 .btn {
  margin: 25px 0 0;
}

.lp4 .btn_link {
  margin-top: 5px;
  display: inline-block;
}

@media (max-width: 768px) {
  .lp4 {
    background-image: inherit;
    padding-right: 0;
    padding-left: 0px;
  }
}

@media (max-width: 1252px) {
  .opened-left-panel .lp4 {
    background-image: inherit;
    background: linear-gradient(317.89deg, #af0f54 2.97%, #054792 95.55%);
  }
}

@media (max-width: 1360px) {
  .opened-contests-panel .lp4 {
    background-image: inherit;
    background: linear-gradient(317.89deg, #af0f54 2.97%, #054792 95.55%);
  }
}

@media (max-width: 1592px) {
  .opened-left-panel.opened-contests-panel .lp4 {
    background-image: inherit;
    background: linear-gradient(317.89deg, #af0f54 2.97%, #054792 95.55%);
  }
}

@media (max-width: 500px) {
  .lp4 {
    padding: 25px 0;
  }
}

@media (max-width: 732px) {
  .opened-left-panel .lp4 {
    padding: 25px;
  }
}

@media (max-width: 840px) {
  .opened-contests-panel .lp4 {
    padding: 25px;
  }
}

@media (max-width: 1072px) {
  .opened-left-panel.opened-contests-panel .lp4 {
    padding: 25px;
  }
}

@media (max-width: 768px) {
  .rank_about__wrapper .lp {
    margin-top: -117px;
  }

  .rank_about__wrapper .lp1__title {
    margin: 0 auto 30px;
    max-width: none;
    font-size: 35px;
    line-height: 44px;
    text-align: center;
  }

  .rank_about__wrapper .lp1__img {
    position: relative;
    left: 50%;
    margin: 0 0 25px;
    transform: translate(-50%) scale(0.9);
  }

  .rank_about__wrapper .lp1 {
    padding: 140px 0 56px;
  }

  .rank_about__wrapper .lp1__list-heading {
    font-size: 15px;
  }

  .rank_about__wrapper .lp-title {
    margin: 0 auto 19px;
    font-size: 35px;
  }

  .rank_about__wrapper .lp2__items {
    margin: 0 auto;
  }

  .rank_about__wrapper .lp2__items>* {
    padding: 0;
    width: 100%;
  }

  .rank_about__wrapper .lp2__item {
    margin: 0 0 15px;
  }

  .rank_about__wrapper .lp-heading {
    margin: 0 auto 37px;
  }

  .rank_about__wrapper .lp2__items> :after {
    content: "";
    position: absolute;
    right: 50%;
    top: auto;
    bottom: -13px;
    transform: translate(50%) rotate(90deg);
  }

  .rank_about__wrapper .lp2__btn,
  .rank_about__wrapper .lp3__signs {
    display: block;
    top: 40px;
  }

  .rank_about__wrapper .lp3 {
    padding: 11px 0 100px;
  }

  .rank_about__wrapper .lp1__list {
    max-width: none;
  }

  .rank_about__wrapper .lp_2 .lp1 {
    padding: 164px 0 0;
  }

  .rank_about__wrapper .lp_2 .lp1__title {
    line-height: 41px;
  }

  .rank_about__wrapper .lp1__img_2 {
    top: -147px;
    margin: 0 0 0 -11px;
    width: 511px;
    height: 377px;
  }

  .rank_about__wrapper .lp_2 .lp2 {
    padding-top: 42px;
  }

  .rank_about__wrapper .lp_2 .lp1__list-text {
    margin: 0 10px;
    font-size: 13px;
  }

  .rank_about__wrapper .lp_2 .lp2 .lp-heading {
    margin-bottom: 49px;
  }

  .rank_about__wrapper .lp3__heading {
    margin-left: 22px;
    margin-right: 22px;
  }
}

@media (max-width: 1272px) {
  .lp2__item {
    height: auto;
  }

  .opened-left-panel .rank_about__wrapper .lp {
    margin-top: -117px;
  }

  .opened-left-panel .rank_about__wrapper .lp1__title {
    margin: 0 auto 30px;
    max-width: none;
    font-size: 35px;
    line-height: 44px;
    text-align: center;
  }

  .opened-left-panel .rank_about__wrapper .lp1__img {
    position: relative;
    left: 50%;
    margin: 0 0 25px;
    transform: translate(-50%) scale(0.9);
  }

  .opened-left-panel .rank_about__wrapper .lp1 {
    padding: 140px 0 56px;
  }

  .opened-left-panel .rank_about__wrapper .lp1__list-heading {
    margin: 0 -5px 10px;
  }

  .opened-left-panel .rank_about__wrapper .lp-title {
    margin: 0 auto 19px;
    font-size: 35px;
    line-height: 45px;
  }

  .opened-left-panel .rank_about__wrapper .lp2__items {
    margin: 0 auto;
  }

  .opened-left-panel .rank_about__wrapper .lp2__items>* {
    padding: 0;
    width: 100%;
  }

  .opened-left-panel .rank_about__wrapper .lp2__item {
    margin: 0 0 28px;
  }

  .opened-left-panel .rank_about__wrapper .lp-heading {
    margin: 0 auto 37px;
  }

  .opened-left-panel .rank_about__wrapper .lp2__items> :after {
    content: "";
    position: absolute;
    right: 50%;
    top: auto;
    bottom: -13px;
    transform: translate(50%) rotate(90deg);
  }

  .opened-left-panel .rank_about__wrapper .lp2__btn,
  .opened-left-panel .rank_about__wrapper .lp3__signs {
    display: none;
  }

  .opened-left-panel .rank_about__wrapper .lp3 {
    padding: 11px 0 100px;
  }

  .opened-left-panel .rank_about__wrapper .lp1__list {
    max-width: none;
  }

  .opened-left-panel .rank_about__wrapper .lp_2 .lp1 {
    padding: 164px 0 0;
  }

  .opened-left-panel .rank_about__wrapper .lp_2 .lp1__title {
    line-height: 41px;
  }

  .opened-left-panel .rank_about__wrapper .lp1__img_2 {
    top: -147px;
    margin: 0 0 0 -11px;
    width: 511px;
    height: 377px;
  }

  .opened-left-panel .rank_about__wrapper .lp_2 .lp2 {
    padding-top: 42px;
  }

  .opened-left-panel .rank_about__wrapper .lp_2 .lp1__list-text {
    margin: 0 10px;
  }

  .opened-left-panel .rank_about__wrapper .lp_2 .lp2 .lp-heading {
    margin-bottom: 49px;
  }

  .opened-left-panel .rank_about__wrapper .lp3__heading {
    margin-left: 22px;
    margin-right: 22px;
  }
}

@media (max-width: 1380px) {
  .opened-contests-panel .rank_about__wrapper .lp {
    margin-top: -117px;
  }

  .opened-contests-panel .rank_about__wrapper .lp1__title {
    margin: 0 auto 30px;
    max-width: none;
    font-size: 35px;
    line-height: 44px;
    text-align: center;
  }

  .opened-contests-panel .rank_about__wrapper .lp1__img {
    position: relative;
    left: 50%;
    margin: 0 0 25px;
    transform: translate(-50%) scale(0.9);
  }

  .opened-contests-panel .rank_about__wrapper .lp1 {
    padding: 140px 0 56px;
  }

  .opened-contests-panel .rank_about__wrapper .lp1__list-heading {
    margin: 0 -5px 10px;
  }

  .opened-contests-panel .rank_about__wrapper .lp-title {
    margin: 0 auto 19px;
    font-size: 35px;
    line-height: 45px;
  }

  .opened-contests-panel .rank_about__wrapper .lp2__items {
    margin: 0 auto;
  }

  .opened-contests-panel .rank_about__wrapper .lp2__items>* {
    padding: 0;
    width: 100%;
  }

  .opened-contests-panel .rank_about__wrapper .lp2__item {
    margin: 0 0 28px;
  }

  .opened-contests-panel .rank_about__wrapper .lp-heading {
    margin: 0 auto 37px;
  }

  .opened-contests-panel .rank_about__wrapper .lp2__items> :after {
    content: "";
    position: absolute;
    right: 50%;
    top: auto;
    bottom: -13px;
    transform: translate(50%) rotate(90deg);
  }

  .opened-contests-panel .rank_about__wrapper .lp2__btn,
  .opened-contests-panel .rank_about__wrapper .lp3__signs {
    display: none;
  }

  .opened-contests-panel .rank_about__wrapper .lp3 {
    padding: 11px 0 100px;
  }

  .opened-contests-panel .rank_about__wrapper .lp1__list {
    max-width: none;
  }

  .opened-contests-panel .rank_about__wrapper .lp_2 .lp1 {
    padding: 164px 0 0;
  }

  .opened-contests-panel .rank_about__wrapper .lp_2 .lp1__title {
    line-height: 41px;
  }

  .opened-contests-panel .rank_about__wrapper .lp1__img_2 {
    top: -147px;
    margin: 0 0 0 -11px;
    width: 511px;
    height: 377px;
  }

  .opened-contests-panel .rank_about__wrapper .lp_2 .lp2 {
    padding-top: 42px;
  }

  .opened-contests-panel .rank_about__wrapper .lp_2 .lp1__list-text {
    margin: 0 10px;
  }

  .opened-contests-panel .rank_about__wrapper .lp_2 .lp2 .lp-heading {
    margin-bottom: 49px;
  }

  .opened-contests-panel .rank_about__wrapper .lp3__heading {
    margin-left: 22px;
    margin-right: 22px;
  }
}

@media (max-width: 1612px) {
  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp {
    margin-top: -117px;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp1__title {
    margin: 0 auto 30px;
    max-width: none;
    font-size: 35px;
    line-height: 44px;
    text-align: center;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp1__img {
    position: relative;
    left: 50%;
    margin: 0 0 25px;
    transform: translate(-50%) scale(0.9);
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp1 {
    padding: 140px 0 56px;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp1__list-heading {
    margin: 0 -5px 10px;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp-title {
    margin: 0 auto 19px;
    font-size: 35px;
    line-height: 45px;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp2__items {
    margin: 0 auto;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp2__items>* {
    padding: 0;
    width: 100%;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp2__item {
    margin: 0 0 28px;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp-heading {
    margin: 0 auto 37px;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp2__items> :after {
    content: "";
    position: absolute;
    right: 50%;
    top: auto;
    bottom: -13px;
    transform: translate(50%) rotate(90deg);
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp2__btn,
  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp3__signs {
    display: none;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp3 {
    padding: 11px 0 100px;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp1__list {
    max-width: none;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp_2 .lp1 {
    padding: 164px 0 0;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp_2 .lp1__title {
    line-height: 41px;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp1__img_2 {
    top: -147px;
    margin: 0 0 0 -11px;
    width: 511px;
    height: 377px;
  }


  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp_2 .lp2 {
    padding-top: 42px;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp_2 .lp1__list-text {
    margin: 0 10px;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp_2 .lp2 .lp-heading {
    margin-bottom: 49px;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp3__heading {
    margin-left: 22px;
    margin-right: 22px;
  }
}

@media (max-width: 370px) {
  .rank_about__wrapper .lp2__item-num {
    transform: scale(0.8);
    transform-origin: right center;
  }

  .rank_about__wrapper .lp2__item {
    padding-left: 30px;
    padding-right: 10px;
  }

  .rank_about__wrapper .lp2__item-img {
    margin-left: -8px;
  }

  .rank_about__wrapper .lp1__img_2 {
    transform: translate(-50%) scale(0.9);
  }
}

@media (max-width: 602px) {
  .opened-left-panel .rank_about__wrapper .lp2__item-num {
    transform: scale(0.8);
    transform-origin: right center;
  }

  .opened-left-panel .rank_about__wrapper .lp2__item {
    padding-left: 30px;
    padding-right: 10px;
  }

  .opened-left-panel .rank_about__wrapper .lp2__item-img {
    margin-left: -8px;
  }

  .opened-left-panel .rank_about__wrapper .lp1__img_2 {
    transform: translate(-50%) scale(0.9);
  }
}

@media (max-width: 710px) {
  .opened-contests-panel .rank_about__wrapper .lp2__item-num {
    transform: scale(0.8);
    transform-origin: right center;
  }

  .opened-contests-panel .rank_about__wrapper .lp2__item {
    padding-left: 30px;
    padding-right: 10px;
  }

  .opened-contests-panel .rank_about__wrapper .lp2__item-img {
    margin-left: -8px;
  }

  .opened-contests-panel .rank_about__wrapper .lp1__img_2 {
    transform: translate(-50%) scale(0.9);
  }
}

@media (max-width: 942px) {
  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp2__item-num {
    transform: scale(0.8);
    transform-origin: right center;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp2__item {
    padding-left: 30px;
    padding-right: 10px;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp2__item-img {
    margin-left: -8px;
  }

  .opened-left-panel.opened-contests-panel .rank_about__wrapper .lp1__img_2 {
    transform: translate(-50%) scale(0.9);
  }
}

.rank-slider__slides {
  display: flex;
  grid-gap: 16px;
}

.rank-slider{
  display: flex;
  justify-content: center;
  position: relative;
  @media (min-width: 1280px) {
    &::after,&::before{
      display: block;
      content:"";
      position: absolute;
      width: 15%;
      height: 100%;
      right: 0;
      background: linear-gradient(to right, transparent , #00392C) ;
      z-index: 2;
      user-select: none;
      pointer-events: none;
    }
    &::before{
      left: 0;
      background: linear-gradient(to left, transparent , #00392C) ;
    }
  }
  
  .swiper{
    min-width: 1040px;
    margin: 0 auto;
    @media (max-width:540px) {
      min-width: 100%;
    }
  }
  .rank-slider__slide {
    position: relative;
    background-size: 100% 100%;
    padding-bottom: 46%;
    background-image: url(~@/assets/images/vip-bg4.png);
    .rank-slider__slider-body {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      padding: 6% 6%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .rank-slider__title {
      color: inherit;
      font-weight: 600;
      font-size: 50px;
      margin-bottom: 0;
      background: linear-gradient(to bottom, #E065B9 0%, #780A5D 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent; 
      @media (max-width:540px) {
        font-size: 30px;
      }
    }
    .rank-slider__details{
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-top: 18px;
      font-size: 18px;
      @media (max-width:540px) {
        font-size: 14px;
        margin-top: 18px;
      }
      .rank-slider__detail{
        display: flex;
        margin-bottom: 4px;
        p{
          width: auto;
          font-size: inherit;
          font-weight: normal;
        }
      }
    }
  }
  .swiper-slide{
    &:nth-child(1),&:nth-child(2),&:nth-child(3),&:nth-child(4),&:nth-child(5),&:nth-child(6){
      .rank-slider__slide{
        background-image: url(~@/assets/images/vip-bg1.png);
        color: #048138;
        .rank-slider__title {
          background: linear-gradient(to bottom, #2CDC6E 0%, #008739 100%);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent; 
        }
      }
    }
    &:nth-child(7),&:nth-child(8),&:nth-child(9),&:nth-child(10),&:nth-child(11){
      .rank-slider__slide{
        background-image: url(~@/assets/images/vip-bg2.png);
        color: #9F6500;
        .rank-slider__title {
          background: linear-gradient(to bottom, #FFBE14 0%, #8C4D00 100%);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent; 
        }
      }
    }
    &:nth-child(12),&:nth-child(13),&:nth-child(14),&:nth-child(15),&:nth-child(16){
      .rank-slider__slide{
        background-image: url(~@/assets/images/vip-bg3.png);
        color: #125CCB;
        .rank-slider__title {
          background: linear-gradient(to bottom, #45BFE9 0%, #023893 100%);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent; 
        }
      }
    }
  }
  
}
.rank-slider__image{
  position: absolute;
  right: 4%;
  top: -10%;
  width: 28%;
}

.rank-slider__value {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #1bb83d;
  text-align: center;
}

.rank-slider__currency {
  color: #fff;
}

.rank-slider__bg {
  position: absolute;
  left: 0;
  top: 20px;
  width: 100%;
  height: calc(100% - 20px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.rank-slider__possibilities {
  text-align: center;
}

.rank-slider__possibility {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #3EAB7E;
}

.rank-slider__possibility--active {
  color: #fff;
}

.rank-slider__possibility--active svg {
  fill: #1bb83d;
}

.rank-slider__arrow:first-child {
  margin-right: 8px;
}

.rank-slider__navigator {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.faq-items {
  margin-bottom: 50px;
  display: flex;
  width: 100%;
}

@media (max-width: 1100px) {
  .faq-items {
    flex-direction: column;
  }
}

@media (max-width: 1332px) {
  .opened-left-panel .faq-items {
    flex-direction: column;
  }
}

@media (max-width: 1440px) {
  .opened-contests-panel .faq-items {
    flex-direction: column;
  }
}

@media (max-width: 1672px) {
  .opened-left-panel.opened-contests-panel .faq-items {
    flex-direction: column;
  }
}

.faq-items__column {
  width: 50%;
  margin-right: 24px;
}

.faq-items__column:last-child {
  margin-right: 0;
}

@media (max-width: 1100px) {
  .faq-items__column {
    width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 1332px) {
  .opened-left-panel .faq-items__column {
    width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 1440px) {
  .opened-contests-panel .faq-items__column {
    width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 1672px) {
  .opened-left-panel.opened-contests-panel .faq-items__column {
    width: 100%;
    margin-right: 0;
  }
}

.faq-element {
  padding: 24px 0;
  border-top: 1px solid #07503F;
  cursor: pointer;
}

.faq-element:last-child {
  border-bottom: 1px solid #07503F;
}

.faq-element__wrapper {
  position: relative;
}

.faq-element__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding-right: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.faq-show .faq-element__title,
.faq-show .faq-element__title:hover {
  color: #D7FFC4;
}

.faq-element__title:hover {
  color: #AED9CC;
}

.faq-element__description {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #86D39D;
  opacity: 0;
  height: 0;
  margin: 0;
  transition: 0.3s all;
}

.faq-show .faq-element__description {
  opacity: 1;
  margin: 17px 0 0;
  height: auto;
}

.faq-element__icon-block {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.faq-element__icon-arrow {
  fill: #AED9CC;
  transition: all 0.2s;
}

.faq-show .faq-element__icon-arrow {
  transform: rotate(180deg);
}

.faq-show {
  max-height: none;
}

@media (max-width:768px) {
  .rank_about__wrapper .lp1 {
    padding: 60px 0 10px;
  }

  .lp2 {
    padding: 0px 0 10px;
  }

  .lp2 .button {
    margin-top: 0;
  }

  .rank_about__wrapper .lp3 {
    padding-bottom: 36px;
  }
}

.cash-btn {
  width: 90%;
  border-radius: 100vh;
  color: #856E00;
  background: linear-gradient( 180deg, #FFED00 0%, #FFBF00 100%);
  padding: 0;
  height: 36px;
  font-size: 16px;
  margin: 0 auto;
  display: block;
  margin-bottom: 26px;
  @media (max-width:768px) {
    padding: 4px 0;
    height: auto;
    font-size: 12px;
    margin-bottom: 10px;
    width: 70%;
  }
}

.btn-icon {
  width: 1.6em;
  margin-right: 15px;
  @media (max-width:768px) {
    margin-right: 6px;
  }
}

.btn-icon2 {
  width: 1.6em;
  margin-right: 15px;
  transform: scale(1.6);
  @media (max-width:768px) {
    margin-right: 6px;
  }
}

.cash-btn2 {
  background-image: linear-gradient(#ED1D49, #ED1D49);
}


.btn-icon-an {
  animation: btn-icon-an 1s infinite;
}

@keyframes btn-icon-an {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}


.swiper-slide{
  transition: .3s transform;
}
.swiper-container{
  padding: 20px 0 ;
  @media (max-width:768px) {
    margin: -20px 0;
  }
}

.cash-title {
  text-align: center;
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width:768px) {
    margin-bottom: 6px;
  }
}


.cash-title span {
  color: #D7FFC4;
  font-size: 14px;
  @media (max-width:768px) {
    font-size: 13px;
  }
}

.cash-title span:last-child {
  color: #FFB422;
  font-weight: bold;
  font-size: 20px;
  @media (max-width:768px) {
    font-size: 12px;
  }
}

.cash-title span:first-child {
  margin-bottom: 16px;
  @media (max-width:768px) {
    margin-bottom: 0;
  }
}

.cash-title-ac {
  flex-direction: row;
  margin-bottom: 10px;
}

.cash-title-ac span:first-child {
  margin-bottom: 0;
}
.lijin-btns-box{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 25px;
  color: #C2F6E6;
  @media (max-width:768px) {
    grid-template-columns: repeat(1,1fr);
    grid-gap: 8px;
  }
  .lijin-btn{
    background-color: #07503F;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 23px 20px;
    @media (max-width:768px) {
      padding: 14px;
    }
    .lijin-icon-box{
      width: 65px;
      height: 65px;
      background: linear-gradient( 149deg, #A8FFED 0%, #C5FF7C 100%);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width:768px) {
        width: 40px;
        height: 40px;
      }
      img{
        width: 60%;
        height: 60%;
        object-fit: contain;
      }
    }
    .lijin-btn-content{
      margin: 0 21px;
      flex: 1;
      @media (max-width:768px) {
        margin: 0 10px;
      }
      .lijin-btn-title{
        font-size: 20px;
        @media (max-width:768px) {
          font-size: 13px;
        }
      }
      .lijin-btn-price{
        font-size: 24px;
        @media (max-width:768px) {
          font-size: 15px;
        }
      }
    }
  }
}
.button_blue.button{
  background-image: url(~@/assets/images/ac-button-bg.png);
  background-size: 100% 100%;
  background-color: transparent !important;
  color: #07503F !important;
}
ul{
  color: #86AFA2;
}

.lp4 {
  .sub-title{
    @media (max-width:768px) {
      font-size: 12px;
      margin-bottom: 17px;
      text-align: center;
      max-width: 100%;
    }
  }
  .sub-title2{
    @media (max-width:768px) {
      font-size: 15px;
      margin-bottom: 11px;
    }
  }
  li{
    @media (max-width:768px) {
      font-size: 13px;
      img{
        width: 12px;
        height: 12px;
        margin-right: 6px;
      }
    }
  }
  h4{
    @media (max-width:768px) {
      font-size: 12px;
    }
  }
  .lp4-content-box{
    @media (max-width:768px) {
      margin-left: 100px;
      position: relative;
      z-index: 2;
    }
  }
}
.about-title{
  color: #FFF;
  @media (max-width:768px) {
    text-align: center;
    font-size: 18px;
  }
}
</style>
